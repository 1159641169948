<template>
  <div class="container">
    <v-row class="">
      <v-col cols="12" class="top-panel">
        <div class="arrow-btn">
          <router-link to="/post-list">
            <img src="../assets/img/arrowBack.svg" class="img-pluss-btn" alt="arrow-back" />
          </router-link>
          <div class="add-btn" @click="editPost">
            <img src="../assets/img/save.svg" alt="save" />
            <p class="add-text">Сохранить</p>
          </div>
        </div>
        <div class="vertical-line-1"></div>
      </v-col>
    </v-row>
    <v-row class="post-container">
      <v-col cols="12" class="title-container py-0">
        <h1 class="page-title" style="margin-left: 17px">
          Редактирование Статьи: {{ this.post.name }}
        </h1>
      </v-col>
      <v-col cols="5">
        <v-col cols="12" class="">
          <div class="content-container">
            <v-col cols="12" class="py-0">
              <div class="col-content px-0 py-0">
                <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                  <p class="col-title">Название статьи</p>
                </v-col>

                <v-col cols="" class="col-paddings-r-l px-0 py-0">
                  <textarea class="col-description textarea-pt" v-model="postName" ref="postNameTextarea"
                    :placeholder="ispostNameFocused ? '' : textareaPlaceholder" @focus="ispostNameFocused = true"
                    @blur="ispostNameFocused = false"></textarea>
                </v-col>
              </div>
            </v-col>
            <div class="horizontal-line"></div>
            <v-col cols="12" class="py-0">
              <div class="col-content px-0 py-0">
                <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                  <p class="col-title">Номер в списке</p>
                </v-col>
                <v-col cols="" class="col-paddings-r-l px-0 py-0">
                  <textarea class="col-description textarea-pt" v-model="postSortOrder" ref="postSortOrderTextarea"
                    :placeholder="ispostSortOrderFocused ? '' : textareaPlaceholder
                      " @focus="ispostSortOrderFocused = true" @blur="ispostSortOrderFocused = false"
                    @input="handleInput"></textarea>
                </v-col>
              </div>
            </v-col>
          </div>
        </v-col>
      </v-col>
      <v-col cols="12" class="title-container-tabs">
        <template>
          <div v-if="loading">
            <v-tabs v-model="activeTab">
              <!-- Добавляем таб "статья Ru" -->
              <v-tab> Cтатья Ru </v-tab>

              <!-- Добавляем таб "статья En" -->
              <v-tab> Cтатья En </v-tab>

              <v-tab-item>
                <!-- Добавляем контент для таба "статья Ru" -->
                <template v-slot:default>
                  <v-col cols="12">
                    <vue-editor v-if="activeTab === 0" v-model="contentRu" :editor-toolbar="customToolbar"></vue-editor>
                    <!-- <div v-html="contentRu"></div> -->
                  </v-col>
                </template>
              </v-tab-item>

              <v-tab-item>
                <!-- Добавляем контент для таба "статья En" -->
                <template v-slot:default>
                  <v-col cols="12">
                    <vue-editor v-if="activeTab === 1" v-model="contentEn" :editor-toolbar="customToolbar"></vue-editor>
                    <!-- <div v-html="contentEn"></div> -->
                  </v-col>
                </template>
              </v-tab-item>
            </v-tabs>
          </div>
        </template>
      </v-col>
      <v-snackbar v-model="snackbar" :timeout="3000" class="btn-contaiener">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
            Закрыть
          </div>
        </template>
      </v-snackbar>
      <!-- <v-col cols="12"> -->
      <!-- Используйте QuillEditor вместо quillEditor -->
      <!-- <vue-editor
          v-model="content"
          :editor-toolbar="customToolbar"
        ></vue-editor>
        <div v-html="content"></div> -->
      <!-- <quill-editor v-model="content" :options="editorOptions" /> -->
      <!-- </v-col> -->
      <!-- <v-col cols="12">
        <button class="btn-create" @click="createArticle">Создать</button>
      </v-col>
      <v-col cols="12">
        <button class="btn-create" @click="editPost">
          Обновить Текст Статьи
        </button>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

// Используйте QuillEditor вместо quillEditor
import { VueEditor, Quill } from "vue2-editor";
//import {Quill, quillEditor as QuillEditor } from "vue-quill-editor";
// Generate code-friendly font names
function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}
function getSizeName(size) {
  return size
    .replace(".", "")
    .replace("%", "pct")
    .replace(/[^a-zA-Z0-9]/g, "");
}

// Specify Quill fonts
const fontList = [
  "Arial",
  "Courier",
  "Garamond",
  "Tahoma",
  "Times New Roman",
  "Verdana",
  "Inter",
  "Monsterat",
];
const fontNames = fontList.map((font) => getFontName(font));
const fonts = Quill.import("formats/font");
fonts.whitelist = fontNames;
Quill.register(fonts, true);
const sizeUnits = ["px", "em", "rem", "%", "pt", "vh", "vw"];
const fontSizeArr = [
  "Font Size in ",
  "8",
  "9",
  "10",
  "12",
  "14",
  "16",
  "20",
  "24",
  "32",
];
const sizeAttributor = Quill.import("attributors/style/size");
sizeAttributor.whitelist = fontSizeArr.map((size) => size + sizeUnits[0]);
Quill.register(sizeAttributor, true);

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function (font) {
  let fontName = getFontName(font);
  fontStyles +=
    ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" +
    fontName +
    "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" +
    fontName +
    "]::before {" +
    "content: '" +
    font +
    "';" +
    "font-family: '" +
    font +
    "', sans-serif;" +
    "}" +
    ".ql-font-" +
    fontName +
    "{" +
    " font-family: '" +
    font +
    "', sans-serif;" +
    "}";
});
let sizeStyles = "";
fontSizeArr.forEach(function (size) {
  sizeUnits.forEach(function (unit) {
    let sizeName = getSizeName(size + unit);
    sizeStyles += `.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="${sizeName}"]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="${sizeName}"]::before {
          content: '${size}${unit}';
          font-size: ${size}${unit};
      }
      .ql-size-${sizeName} {
          font-size: ${size}${unit};
      }
      `;
  });
});

const styles = document.createElement("style");
styles.innerHTML = fontStyles + sizeStyles;
document.body.appendChild(styles);

export default {
  name: "PostBody",
  components: { VueEditor }, // Здесь исправлено на QuillEditor
  data: () => ({
    content: "",
    loading: "",
    post: {},
    ispostNameFocused: false,
    postName: "",
    postSortOrder: null,
    ispostSortOrderFocused: false,
    activeTab: 0,
    contentRu: "",
    contentEn: "",
    newPost: {},
    snackbar: false,
    message: "",
    customToolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ font: fonts.whitelist }],
      [{ size: sizeAttributor.whitelist }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["blockquote", "code-block"],
      ["link", "image", "video"],
      ["clean"],
    ],
    editorOptions: {
      modules: {
        customToolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ font: [fonts.whitelist] }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],

          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["blockquote", "code-block"],
          ["link", "image", "video"],
          ["clean"],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
      syntax: true,
      theme: "snow", // или "bubble"
    },
  }),
  mounted() {
    this.applyCustomFontStyle();
  },
  created() {
    this.getPost();
  },
  computed: {
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },
  watch: {
    content() {
      this.$nextTick(() => {
        this.applyCustomFontStyle();
      });
    },
    postName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("postNameTextarea");
      });
    },
    postSortOrder() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("postSortOrderTextarea");
      });
    },
  },
  methods: {
    handleInput() {
      // Оставить только цифры
      this.postSortOrder = this.postSortOrder.replace(/\D/g, "");
    },
    getPost() {
      this.$http
        .get(`/api/Post/${this.$route.params.postId}`)
        .then((response) => {
          this.post = response.data;
          this.contentRu = this.post.htmlBody;
          this.postName = this.post.name;
          this.post.localizations.forEach((localization) => {
            // Проверка, что текущая локализация имеет имя (если есть)
            if (localization.name) {
              // Здесь вы можете использовать localization.name по вашему усмотрению
              this.categoryNameEn = localization.name;
              this.contentEn = localization.htmlBody;
            }
          });
          this.postSortOrder = this.post.sortOrder;
          this.loading = true;
        })
        .catch((error) => {
          alert(error);
        });
    },
    editPost() {
      this.post.htmlBody = this.contentRu || this.post.htmlBody;
      this.post.name = this.postName || this.post.name;

      this.post.localizations.forEach((localization) => {
        if (localization.name) {
          localization.name = this.categoryNameEn || localization.name;
          localization.htmlBody = this.contentEn || localization.htmlBody;
        }
      });

      this.post.sortOrder = this.postSortOrder || this.post.sortOrder;
      this.$http
        .put(`/api/Post`, this.post, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.snackbar = true;
          this.message = "Статья успешно изменена";
        })
        .catch((error) => {
          console.log(error);

          this.snackbar = true;

          if (error.response && error.response.status === 400) {
            this.message = "Заполните Статью";
          } else {
            this.message = "Ошибка при сохранении Статьи";
          }
        });
    },
    applyCustomFontStyle() {
      const fontPicker = document.querySelector(".ql-font .ql-picker-label");
      if (fontPicker) {
        fontPicker.style.fontFamily = "Inter, sans-serif";
      }
    },
    createArticle() {
      // Добавьте код для обработки создания статьи
      console.log("Article created!");
    },
    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },

    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
    hidePlaceholder(inputName) {
      if (inputName === "legalAdress") {
        this.isLegalAdressFocused = true;
      } else if (inputName === "actualAdress") {
        this.isActualAdressFocused = true;
      }
    },
  },
};
</script>

<style scoped>
.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}

.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}

.btn-photo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.img-container-flex {
  display: flex;
  justify-content: space-between;
}

.uploaded-image-logo {
  /* max-width: 392px; 
  max-height: 382px;  */
  max-width: 100%;
  max-height: 100%;
}

.uploaded-image-container {
  width: auto;
  height: auto;
  border-radius: 10px;
  border: 1px solid var(--05, #dae3ea);
  background: var(--06, #fff);
  padding: 14px;
}

.information-text-3 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 30px;
}

.information-text-2 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 43px;
}

.information-text-1 {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}

.photo-description {
  display: flex;
  align-items: center;
}

.content-container {
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  background: var(--white, #fff);
  margin-top: 0px;
}

.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}

.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black;
  /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
}

::v-deep.v-tabs .v-tabs-bar .v-tabs-slider {
  background-color: #bcb648 !important;
}

::v-deep .v-tabs-slider-wrapper {
  height: 4px !important;
}

.page-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 12px;
}

::v-deep .v-tab:before {
  background-color: gray;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

::v-deep .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::v-deep .v-tab {
  caret-color: #ff0000 !important;
  color: #000 !important;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  font-size: 1.1rem;
  font-weight: 600;
  font-style: normal;
  justify-content: center;
  letter-spacing: normal;
  line-height: normal;
  min-width: 90px;
  max-width: 360px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: none;
  transition: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-family: "Inter";
}

.title-container-tabs {
  padding-left: 25px;
  padding-right: 30px;
  padding-top: 0px;
}

.title-container {
  margin-top: 17px;
}

/* .post-container {
  margin-left: 25px;
  margin-right: 30px;
} */

.img-pluss-btn {
  margin-left: 10px;
}

.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}

.horizontal-line-not-full {
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
  margin-left: 12px;
  margin-bottom: 30px;
}

.full-text-input-3 {
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input-2 {
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input {
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input,
.full-text-input-2,
.full-text-input-3,
::placeholder {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.full-text-input,
:focus {
  outline: none;
  /* Убираем внешний контур (outline) при фокусе */
  border: none;
  /* Убираем границу (border) при фокусе */
  box-shadow: none;
  /* Убираем тень (box-shadow) при фокусе */
}

.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
  /* height: 200px; */
  display: flex;
  align-items: center;
}

.textarea-pt {
  padding-top: 19px;
}

.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
  margin-left: 12px;
}

.col-content {
  display: flex;
  align-items: center;
}

.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  width: 100%;
  min-height: 20px;
  /* Минимальная высота для textarea */
}

.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}

.information-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.content-container {
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  background: var(--white, #fff);
  margin-top: 0px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "Normal";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "Large";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "Huge";
  font-size: 18px !important;
}

/* Set droplist names - -item is the currently selected font, -label is the font's appearance in the droplist */
/* .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Inter']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Inter']::before {
  content: 'Inter';
  font-family: 'Inter', sans-serif;
} */
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "Интер";
  font-family: "Inter", sans-serif;
}

.ql-font span[data-value="Inter"]::before {
  font-family: "Inter";
  content: "Roboto" !important;
}

::v-depp .ql-snow .ql-picker.ql-font .ql-picker-label::before,
::v-depp .ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "Inter";
  font-family: "Inter", sans-serif;
}

.btn-create {
  border-radius: 8px;
  background: var(--03, #bcb648);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  color: var(--06, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.page-title {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>
